import React, { Fragment, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import {
  ProductDetails,
  Checkout,
  OrderHistory,
  MainNavigation,
  DynamicContent,
  RestaurantSearch,
  Favorites,
  Homepage,
  UpdateCode,
  LoginCode,
  NotMe,
  Main,
  TimeComponent,
  Restaurant,
  RestaurantCategory,
  SelectAddress,
  Worldpay,
} from "./features/eComm";

import { useMediaQuery } from "@material-ui/core";

import {
  Welcome,
  WelcomeQr,
  About,
  OrderNow,
  Team,
  Contact,
  PrivacyPolicy,
  Mobile,
  TermsandCondition,
} from "./features/eComm/pages";
import { Loader } from "./components";
import { ToastContainer } from "react-toastify";
//import { setLoading, setUser } from "./redux/publicReducer/actions";
import { useSelector } from "react-redux";

import { HomeScreen } from "./features/public/HomeScreen";
import { HomepageLanding } from "./features/public/HomepageLanding";
import { Signin } from "./features/public/Signin";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { useAlan } from "./hooks/useAlan";
import { NewLayout, NewCategory } from "./Sandbox";
import { CartPage } from "./features/eComm/cart/CartPage";
import { PaymentHandler } from "./features/paymentHandler/";
import "react-calendar/dist/Calendar.css";
import "./App.scss";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Askme from "features/public/AskMe";
import { v4 as uuidV4 } from "uuid";
import Saphire from "features/eComm/sicc/Saphire";
import ConversationList from "features/eComm/sicc/ConversationList";
import ConversationDetails from "features/eComm/sicc/ConversationDetails";
import SiccLayout from "features/eComm/sicc/SiccLayout";
import { Transactions } from "features/eComm/pages/Transactions";
import { Profile } from "features/eComm/pages/Profile";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_SITE_NAME;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;

function App() {
  useEffect(() => {
    document.title = "Expect more from retirement";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { loading } = useSelector((state) => state.public);

  const isMobile = useMediaQuery("(max-width:0px)");

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/sync-to-menu-info/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        //const data = response.data;
      })
      .catch((error) => {
        return;
      });

    const senderId = localStorage.getItem("sender_id");
    const pinCode = sessionStorage.getItem("pinCodeOk");
    const upToDate = localStorage.getItem("rasa_details_up_to_date");
    if (!senderId) {
      localStorage.setItem("sender_id", uuidV4());
    }

    if (pinCode && !upToDate) {
      const lastName = sessionStorage.getItem("lastName");
      const firstName = sessionStorage.getItem("firstName");
      const middleName = sessionStorage.getItem("middleName") || "";
      axios
        .put(
          "https://e247509dda4b.ngrok.app/conversations/add-details/" +
            senderId,
          {
            lastName,
            firstName,
            middleName,
            siteId,
            siteName,
          }
        )
        .then((response) => {
          localStorage.setItem("upToDate", true);
        })
        .catch((error) => {
          return;
        });
    }
  }, []);

  return (
    <>
      <Loader open={loading} />
      <Switch>
        {parseInt(siteId) === 175 ? (
          [
            <Route
              key="root"
              exact
              path="/"
              component={!isMobile ? HomeScreen : Mobile}
            />,
            <Route key="askme" exact path="/askme" component={Askme} />,
            <Route key="ruby" exact path="/ruby" component={Saphire} />,

            <Route
              key="conversations"
              exact
              path="/conversations"
              component={ConversationList}
            />,
            <Route
              key="conversationDetails"
              exact
              path="/conversations/:senderId"
              component={ConversationDetails}
            />,
          ]
        ) : (
          <Route
            exact
            path="/"
            component={!isMobile ? HomepageLanding : Mobile}
          />
        )}

        <Route exact path="/sandbox" component={NewLayout} />
        <Route exact path="/sand-cat" component={NewCategory} />
        <Route exact path="/sand-time" component={TimeComponent} />

        <Route exact path="/search/" component={RestaurantSearch} />
        <Route exact path="/payment-handler" component={PaymentHandler} />
        <Route exact path="/cart/" component={CartPage} />

        <Route exact path="/sign-in" component={Signin} />
        {/* <Route exact path="/home" component={HomepageLanding} /> */}

        {/* Pages */}
        <Route exact path="/home" component={HomepageLanding} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/transactions" component={Transactions} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/about" component={About} />
        <Route exact path="/ordernow" component={OrderNow} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/Team" component={Team} />
        <Route exact path="/mobile" component={Mobile} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsandCondition}
        />
        <Route path="/checkout" component={Checkout} />
        <Route
          exact
          path="/order-history"
          component={() => {
            return (
              <>
                <MainNavigation />
                <OrderHistory />
              </>
            );
          }}
        />
        <Route exact path="/select-address" component={SelectAddress} />
        <Route exact path="/payment-wpay/" component={Worldpay} />
        {/* <Route exact path="/home/" component={RestaurantLayout} /> */}
        <Route exact path="/main/" component={Main} />
        <Route exact path="/homepage/" component={Homepage} />
        <Route exact path="/updatecode/" component={UpdateCode} />
        <Route exact path="/logincode/" component={LoginCode} />
        <Route exact path="/notme/" component={NotMe} />
        <Route
          exact
          path={["/menu/", "/menu/:productName"]}
          component={Restaurant}
        />
        {enableRevenueCenter != 1 ? (
          <Route
            exact
            path={["/:categoryName", "/:categoryName/:productName"]}
            component={RestaurantCategory}
          />
        ) : (
          <Route exact path="/:revenueCenterName" component={WelcomeQr} />
        )}

        <Route path="/:categoryName/:productName" component={ProductDetails} />

        <Route exact path="/favorites/" component={Favorites} />
        <Route path="/menu/:productName" component={ProductDetails} />

        <Route path="/dynamic/:page/:contentId" component={DynamicContent} />
      </Switch>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default withRouter(App);

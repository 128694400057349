import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import SiccLayout from "./SiccLayout";
import axios from "axios";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const ProgressWithLabel = ({ value, color, label }) => {
  return (
    <Box position="relative" display="inline-flex" width="100%">
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: "100%",
          "& .MuiLinearProgress-bar": {
            backgroundColor: (theme) =>
              color ? color : theme.palette.primary.main,
          },
          height: "16px",
          borderRadius: 2,
        }}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="white">
          {label} {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const ConversationList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get("siteId");

  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);

  // Pull from conversations API
  const getConversations = async () => {
    try {
      const { data } = await axios.get(
        "https://e247509dda4b.ngrok.app/conversations"
      );

      const newData = data
        .reverse()
        .map((item, index) => ({ ...item, id: index }));

      if (siteId) {
        setConversations(newData.filter((item) => item.siteId === siteId));
      } else {
        setConversations(newData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const confidenceColorLevels = (val) => {
    if (val <= 0.25) {
      return "red";
    } else if (val <= 0.5) {
      return "yellow";
    } else if (val <= 0.75) {
      return "orange";
    } else {
      return "green";
    }
  };

  useEffect(() => {
    getConversations();
  }, []);

  return (
    <SiccLayout>
      <Box>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ my: 2 }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Ruby AI Conversation
              </Typography>
            </Box>
            <Box sx={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={conversations}
                columns={[
                  {
                    headerName: "Sender ID",
                    flex: 1,
                    field: "sender_id",
                    renderCell: (params) => (
                      <Link href={"/conversations/" + params.value}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    headerName: "First Name",
                    flex: 1,
                    field: "firstName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Last Name",
                    flex: 1,
                    field: "lastName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site Name",
                    flex: 1,
                    field: "siteName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site ID",
                    width: 80,
                    field: "siteId",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Confidence",
                    flex: 1,
                    field: "averageConfidence",
                    headerAlign: "center",
                    renderCell: (params) => (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              textAlign: "center",
                            }}
                          >
                            <ProgressWithLabel
                              value={params.value * 100}
                              color={confidenceColorLevels(params.value)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    headerName: "Source",
                    flex: 1,
                    field: "openAIAnswersPercentage",
                    headerAlign: "center",
                    renderCell: (params) => {
                      console.log(params);
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            textAlign: "center",
                          }}
                        >
                          <ProgressWithLabel
                            value={params.value}
                            label={"OpenAI"}
                          />
                          <ProgressWithLabel
                            value={params.row.rasaIntentAnswersPercentage}
                            label={"Rasa"}
                            color="#FF007F"
                          />
                        </Box>
                      );
                    },
                  },
                ]}
                slots={{
                  toolbar: CustomToolbar,
                }}
                loading={loading}
                sx={{
                  width: "100%",
                  padding: 2,
                }}
              />
            </Box>
          </Container>
        </Box>
      </Box>
    </SiccLayout>
  );
};

export default ConversationList;

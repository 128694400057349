import "../../../assets/css/custom.css";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Container,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { ExpandCircleDown, Send } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import saphireImg from "../../../assets/saphire.png";
import { stringAvatar } from "utils/helpers";

const drawerWidth = 240;
const SaphireConvo = ({ defaultConversations, convoSetter, convoDetails }) => {
  const [inputVal, setInputVal] = useState("");
  const [conversations, setConversations] = useState(defaultConversations);
  const [typing, setTyping] = useState(false);

  const messagesEndRef = useRef(null);
  const scrollableRef = useRef(null);
  const downRef = useRef(null);

  const handleSend = async () => {
    setTyping(true);
    setConversations((prev) => [
      ...prev,
      { sender: "user", message: inputVal },
    ]);
    convoSetter((prev) =>
      prev.map((item) => {
        if (item.id === convoDetails.id) {
          item.messages = [
            ...item.messages,
            { sender: "user", message: inputVal },
          ];

          if (!item.title) {
            item.title = inputVal;
          }
        }
        return item;
      })
    );
    setInputVal("");
    try {
      const { data } = await axios.post(
        "https://8fd9b806d3c7.ngrok.app/webhooks/rest/webhook",
        {
          sender: convoDetails?.id || "default",
          message: inputVal,
        }
      );
      setConversations((prev) => [
        ...prev,
        {
          sender: "ruby",
          message: data[0]?.text || "Sorry, I am unable to answer the question",
        },
      ]);

      convoSetter((prev) =>
        prev.map((item) => {
          if (item.id === convoDetails.id) {
            item.messages = [
              ...item.messages,
              {
                sender: "ruby",
                message:
                  data[0]?.text || "Sorry, I am unable to answer the question",
              },
            ];
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setTyping(false);
    }
  };

  const handleScroll = (event) => {
    const element = event.target;

    if (
      element.scrollHeight - element.clientHeight ===
      parseInt(element.scrollTop.toFixed(0))
    ) {
      downRef.current.style.display = "none";
    } else {
      downRef.current.style.display = "block";
    }
  };

  const scrollToLatest = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      scrollToLatest();
    }
  }, [conversations]);

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  return (
    <Box
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        height: "100vh",
        maxHeight: "750px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {conversations.length > 0 ? (
        <Box
          ref={scrollableRef}
          onScroll={handleScroll}
          width="100%"
          height="calc(100% - 131px)"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(0,0,0,.4)",
              borderRadius: "10px",
            },
          }}
        >
          {conversations.map((convo, index) => {
            if (convo.sender === "user") {
              return (
                <Box key={index} bgcolor="white" padding={2}>
                  <Container
                    maxWidth="sm"
                    sx={{
                      display: "flex",

                      gap: 2,
                    }}
                  >
                    <Avatar
                      alt={"John Doe"}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/dependent-images/" +
                        "John Doe"?.replace(" ", "-").toLowerCase() +
                        ".png"
                      }
                      {...stringAvatar("John Doe", {})}
                    />
                    <Typography>{convo.message}</Typography>
                  </Container>
                </Box>
              );
            } else {
              return (
                <Box
                  key={index}
                  padding={2}
                  sx={{
                    background: "rgba(0,0,0,.02)",
                  }}
                >
                  <Container
                    maxWidth="sm"
                    sx={{
                      display: "flex",

                      gap: 2,
                    }}
                  >
                    <Avatar src={saphireImg} />
                    <Typography>{convo.message}</Typography>
                  </Container>
                </Box>
              );
            }
          })}
          {typing && (
            <Box
              padding={2}
              sx={{
                background: "rgba(0,0,0,.02)",
              }}
            >
              <Container
                maxWidth="sm"
                sx={{
                  display: "flex",

                  gap: 2,
                }}
              >
                <Avatar src={saphireImg} />
                <div className="dot-loader dark">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Container>
            </Box>
          )}
          <div ref={messagesEndRef} />
        </Box>
      ) : (
        <Box
          width="100%"
          height="100%"
          bgcolor="white"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Box>
      )}
      <Divider />
      <Box
        bgcolor="white"
        width="100%"
        alignSelf={"end"}
        padding={5}
        position="relative"
      >
        <IconButton
          ref={downRef}
          sx={{
            display: "none",
            position: "absolute",
            top: -80,
            right: 30,
            transition: "all 0.6s",
          }}
          size="large"
          onClick={scrollToLatest}
        >
          <ExpandCircleDown
            fontSize="large"
            sx={{
              color: (theme) => theme.palette.grey[600],
            }}
          />
        </IconButton>
        <Container maxWidth="sm">
          <FormControl
            sx={{
              width: "100%",
              boxShadow: (theme) => theme.shadows[5],
              borderRadius: "5px",
            }}
          >
            <OutlinedInput
              placeholder="Ask me anything"
              onKeyDown={handleEnter}
              endAdornment={
                <InputAdornment position="end">
                  {typing ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <div className="dot-loader dark">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleSend}
                    >
                      <Send />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              onChange={(e) => setInputVal(e.target.value)}
              value={inputVal}
            />
          </FormControl>
        </Container>
      </Box>
    </Box>
  );
};

SaphireConvo.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SaphireConvo;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  ChatBubbleOutline,
  Delete,
  DeleteOutline,
} from "@mui/icons-material";
import "../../../assets/css/custom.css";
import { useState } from "react";
import SaphireConvo from "./SaphireConvo";
import Swal from "sweetalert2";
import axios from "axios";
import { v4 as uuidV4 } from "uuid";
import { getRasaMessages } from "utils/helpers";

const drawerWidth = 240;
const bg = "rgb(32 33 35)";

const Saphire = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  const [value, setValue] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNewChat = (e) => {
    e.preventDefault();
    const newId = uuidV4();
    setConversations((prev) => [
      ...prev,
      {
        id: newId,
        title: "",
        messages: [],
      },
    ]);
    setValue(newId);
    localStorage.setItem("storedConversations", JSON.stringify(conversations));
  };

  const handleOnEditMode = (id) => {
    const convo = conversations.find((conv) => conv.id === id);
    Swal.fire({
      title: "Enter new title",
      html: `<input type="text" id="title" value='${convo.title}' class="swal2-input" placeholder="New title">`,
      confirmButtonText: "Submit",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      focusConfirm: false,
      preConfirm: () => {
        const title = Swal.getPopup().querySelector("#title").value;

        if (!title) {
          Swal.showValidationMessage("Please enter a title");
        }

        return title;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setConversations((prev) =>
          prev.map((conv) => {
            if (conv.id === convo.id) {
              conv.title = result.value;
            }
            return conv;
          })
        );
        localStorage.setItem(
          "storedConversations",
          JSON.stringify(conversations)
        );
        Swal.fire({
          title: "Success!",
          html: `Title has been changed!`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this conversation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newConversation = conversations.filter(
          (convo) => convo.id !== id
        );
        setConversations(newConversation);
        setValue(conversations[0]?.id);

        localStorage.setItem(
          "storedConversations",
          JSON.stringify(newConversation)
        );
        Swal.fire({
          title: "Success!",
          html: `Title has been changed!`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  };

  const getConversations = async () => {
    setLoading(true);
    let storedConversations = localStorage.getItem("storedConversations") || [];

    if (storedConversations.length === 0) {
      const initialId = uuidV4();
      storedConversations = [
        {
          id: initialId,
          title: "New conversation",
          messages: [],
        },
      ];
      localStorage.setItem(
        "storedConversations",
        JSON.stringify(storedConversations)
      );
    } else {
      storedConversations = JSON.parse(storedConversations);
    }

    try {
      const { data } = await axios.get(
        "https://e247509dda4b.ngrok.app/conversations/"
      );

      const initConvo = storedConversations.map((item) => {
        const conversation = data.find((conv) => conv.sender_id === item.id);

        return {
          ...item,
          messages: getRasaMessages(conversation),
        };
      });
      setConversations(initConvo);
      setValue(initConvo[0]?.id);
    } catch (error) {
      console.log(error, "Err");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    localStorage.setItem("storedConversations", JSON.stringify(conversations));
  }, [conversations]);

  const drawer = (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box bgcolor={bg} padding={1}>
        <button
          className="MuiButton-root"
          style={{
            borderColor: "#fff",
            padding: "8px",
            width: "100%",
            border: "1px solid rgba(255,255,255,.3)",
            background: "transparent",
            display: "flex",
            color: "white",
            alignItems: "center",
          }}
          onClick={handleNewChat}
        >
          <Add />
          <span>New Chat</span>
        </button>
      </Box>
      <List
        sx={{
          background: bg,
          color: "white",
          height: "calc(100% - 115px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: bg,
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255,255,255,.2)",
            borderRadius: "10px",
          },
          padding: "4px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          conversations.map((convo, index) => (
            <ListItem
              onClick={() => {
                setValue(convo.id);
              }}
              key={index}
              disablePadding
            >
              <Tooltip title={convo.title} placement="right">
                <ListItemButton
                  sx={{
                    borderRadius: 2,
                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                    },
                    backgroundColor:
                      convo.id === value ? "rgba(0, 0, 0, 0.8)" : "transparent",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    padding: 0,
                    position: "relative",
                  }}
                >
                  {convo.id === value && (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(90deg, transparent, rgba(0,0,0,.4), black 80%)",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          sx={{
                            borderRadius: 2,
                            color: "white",
                          }}
                          size="small"
                          onClick={(e) => handleOnEditMode(convo.id)}
                        >
                          <BorderColorOutlined fontSize="5px" />
                        </IconButton>
                        <IconButton
                          sx={{
                            borderRadius: 2,
                            color: "red",
                          }}
                          size="small"
                          onClick={(e) => handleDelete(convo.id)}
                        >
                          <DeleteOutline fontSize="5px" />
                        </IconButton>
                      </Box>
                    </>
                  )}
                  <IconButton
                    sx={{
                      color: "white",
                    }}
                  >
                    <ChatBubbleOutline color="inherit" fontSize="inherit" />
                  </IconButton>
                  <Typography noWrap textOverflow={"hidden"}>
                    {convo.title ? convo.title : "New conversation"}
                  </Typography>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))
        )}
      </List>
      <Divider />
      <List
        sx={{
          background: bg,
          color: "white",
        }}
      >
        <ListItem disablePadding>
          <Tooltip title={"Clear Conversations"} placement="right-end">
            <ListItemButton
              sx={{
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: 0,
              }}
              onClick={() => setConversations([])}
            >
              <IconButton
                sx={{
                  color: "white",
                }}
              >
                <Delete color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography noWrap textOverflow={"hidden"}>
                Clear Conversations
              </Typography>
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100vh",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100vh",
              maxHeight: "750px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {conversations.map((convo, index) => {
        if (convo.id === value) {
          return (
            <SaphireConvo
              key={index}
              convoDetails={convo}
              defaultConversations={convo?.messages || []}
              convoSetter={setConversations}
            />
          );
        } else {
          return null;
        }
      })}
    </Box>
  );
};

Saphire.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Saphire;

import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import { useHistory, useLocation } from 'react-router-dom';


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});



export default function CustomizedBreadcrumbs({ selectedRevenueCenter }) {
  const history = useHistory();
  const {state} = useLocation();
  console.log("HOME", state)
  // const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(state?.revenueCenter || "none");
  
  return (
    <div role="presentation" className="breadcrumbs">
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "10px 26px 0" }}>
        
        <StyledBreadcrumb
          component="a"
          label="Menu"
          icon={<RestaurantRoundedIcon fontSize="small" />}
          onClick={() => {
            history.push({
              pathname: "/welcome",
              state:{
                ...state,
                menuName : "",
                revenueCenter : ""
              }
            });
          }}
        />
        {selectedRevenueCenter !== "none" && selectedRevenueCenter ? (
              <StyledBreadcrumb component="a" label={selectedRevenueCenter} 
              onClick={() => {
                  history.push({
                    pathname: "/welcome",
                    state:{
                      ...state,
                      menuName : ""
                    }
                  });
                }}/>
          ) : state?.revenueCenter ? (
            <StyledBreadcrumb component="a" label={state.revenueCenter} 
            onClick={() => {
                history.push({
                  pathname: "/welcome",
                  state:{
                    ...state,
                    menuName : ""
                  }
                });
              }}/>
        ) : null}
      
      {/* {state?.revenueCenter ? (
              <StyledBreadcrumb component="a" label={state.revenueCenter} 
              onClick={() => {
                  history.push({
                    pathname: "/welcome",
                    state
                  });
                }}/>
          ) : null} */}

      {state?.menuName ? (
          <StyledBreadcrumb component="a" label={state.menuName} 
          />
      ) : null}
      </Breadcrumbs>
    </div>
  );
}
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Theme } from "./features/theme/Theme";
import swDev from "./swDev";
import * as serviceWorker from "./serviceWorker";
import "typeface-poppins";

var sourcePWA = new EventSource(
  "https://ecommv2.servingintel.com/pwa/check_update"
);
sourcePWA.onmessage = function (event) {
  var u_items = JSON.parse(event.data);
  //console.log(u_items[0].utime);
  if (u_items[0].time != "") {
    if (sessionStorage.getItem("pwa_update_date_time") !== u_items[0].time) {
      caches.delete("appV1");
      sessionStorage.setItem("pwa_update_date_time", u_items[0].time);
      window.location.reload(true);
    }
  }
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Theme>
        <App />
      </Theme>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
swDev();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

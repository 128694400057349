import { styled } from "@material-ui/core";
import { Button } from "@mui/material";
import React from "react";

const StyledButton = styled("button")({
  padding: "1rem 1rem",
  background: "#e5e5e5",
  color: "white",
  width: "100%",
  fontSize: "1.2rem",
  fontWeight: "500",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  maxHeight: "120px",
  "&:hover": {
    background: "#e5e5e5",
  },
});

const DisabledButton = ({ label, children }) => {
  return (
    <StyledButton className="MuiButton-root" disabled={true}>
      {children}
    </StyledButton>
  );
};

export default DisabledButton;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { QRDialog } from "./QrDialog";
import { NotificationBell } from "../eComm";
import { useSelector } from "react-redux";
import background from "../../assets/images/homescreen-bg.png";
import logo from "../../assets/images/logo.png";
import { Box, Container, Grid } from "@mui/material";
import {
  AccountBalanceWalletRounded,
  AccountCircleRounded,
  ReceiptRounded,
  RestaurantMenuRounded,
} from "@mui/icons-material";
import DisabledButton from "features/eComm/components/DisabledButton";
import { MyBalanceDialog } from "features/eComm/components/LogoCenter";
import axios from "axios";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;
const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
export const HomeScreen = () => {
  const [openCamera, setOpenCamera] = useState(false);
  const firstName = sessionStorage.getItem("firstName") || null;
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const [openDialog, setOpenDialog] = useState(false);
  const mBalance = mBal.toFixed(2);
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        console.log(customer, "cust");
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <MyBalanceDialog
        open={openDialog}
        setOpen={setOpenDialog}
        mealBalance={mealBalance}
        mBalance={mBalance}
      />
      <div
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 100 }}
      >
        {user && <NotificationBell />}
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: `linear-gradient(rgba(255,255,255,.05), rgba(0,0,0,.4)), url(${background})no-repeat center center`,
          color: "white",
          position: "fixed",
          top: "0px",
          left: "0px",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Typography>
            <img src={logo} style={{ width: "400px" }} />
          </Typography>
        </div>
        <Typography
          variant={isMobile ? "p" : "h6"}
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginBottom: "1rem",
            maxWidth: "500px",
            color: "white",
          }}
        >
          Discover a Life of Comfort and Fulfillment in Our Senior Living
          Community
        </Typography>
        {firstName ? (
          <Container maxWidth="sm">
            <Grid
              container
              spacing={3}
              justifyItems={"stretch"}
              sx={{
                margin: "0!important",
              }}
            >
              {[
                {
                  label: "In-room Ordering",
                  Icon: RestaurantMenuRounded,
                  path: "/weolcome",
                  disabled: true,
                },
                {
                  label: "My Balance",
                  Icon: AccountBalanceWalletRounded,
                  path: "/",
                  disabled: false,
                  action: () => setOpenDialog(true),
                },
                {
                  label: "My Transactions",
                  Icon: ReceiptRounded,
                  path: "/transactions",
                  disabled: false,
                },
                {
                  label: "My Profile",
                  Icon: AccountCircleRounded,
                  path: "/profile",
                  disabled: false,
                },
                {
                  label: "Ruby AI",
                  Icon: null,
                  path: "/askme",
                  disabled: true,
                },
              ].map(({ Icon, ...item }, index) =>
                item.label === "Ruby AI" && siteId == 175 ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      padding: "8px!important",
                    }}
                  >
                    {item.disabled ? (
                      <DisabledButton>
                        <div
                          style={{
                            color: "#acacac",
                            fontWeight: "500",
                            fontSize: "1.5rem",
                          }}
                        >
                          Ruby AI
                        </div>
                      </DisabledButton>
                    ) : (
                      <button
                        className="MuiButton-root "
                        style={{
                          borderRadius: "15px!important",
                          padding: "1rem 2rem",
                          width: "100%",
                          border: "1px solid #FF007F",
                          background: "#ebebeb",
                          position: "relative",
                          height: "100%",
                          maxHeight: "120px",
                        }}
                        onClick={() => {
                          history.replace("/askme", {
                            from: history.location.pathname,
                          });
                        }}
                        disabled={item.disabled}
                      >
                        <div
                          style={{
                            color: "#ffff",
                            fontWeight: "500",
                            fontSize: "1.5rem",
                          }}
                        >
                          Ruby AI
                        </div>
                        {!item.disabled && (
                          <div
                            className={`pulse`}
                            style={{
                              background: "#ffff",
                              borderRadius: 0,
                              height: "110%",
                            }}
                          ></div>
                        )}
                      </button>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    key={index}
                    item
                    xs={6}
                    sm={6}
                    sx={{
                      padding: "8px!important",
                    }}
                  >
                    {item.disabled ? (
                      <DisabledButton label={item.label}>
                        <div style={{ marginRight: 2 }}>
                          <Icon />
                        </div>
                        <div
                          style={{
                            color: "#acacac",
                          }}
                        >
                          {item.label}
                        </div>
                      </DisabledButton>
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          padding: "1rem 1rem",
                          backgroundColor: "rgb(10, 61, 98)",
                          color: "white",
                          width: "100%",
                          fontSize: "1.2rem",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          maxHeight: "120px",
                        }}
                        onClick={
                          item.action
                            ? item.action
                            : () => {
                                history.push(item.path);
                              }
                        }
                        disabled={item.disabled}
                      >
                        <div style={{ marginRight: 2 }}>
                          <Icon />
                        </div>
                        <div
                          style={{
                            color: "#ffff",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          {item.label}
                        </div>
                      </Button>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Container>
        ) : (
          <>
            <Button
              variant="contained"
              style={{
                borderRadius: "15px",
                marginTop: "1rem",
                padding: "1rem 2rem",
                backgroundColor: "rgb(10, 61, 98)",
                color: "white",
                width: "100%",
                maxWidth: "300px",
                fontSize: "1.5rem",
                fontWeight: "500",
              }}
              onClick={() => {
                history.push("/home");
              }}
            >
              Login
            </Button>
          </>
        )}
      </div>
    </>
  );
};

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const resolver = (schema) => {
  const yupSchema = Yup.object().shape(schema);
  return yupResolver(yupSchema);
};

export const capitalize = (text) => {
  //split the above string into an array of strings
  //whenever a blank space is encountered

  const arr = text.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(" ");
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, addedSx) => {
  const splittedText = name.split(" ");
  const children =
    splittedText.length > 1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}`;
  return {
    sx: {
      ...addedSx,
      bgcolor: stringToColor(name),
    },
    children,
  };
};

export const getRasaMessages = (conversation = {}) => {
  let messages = [];
  if (conversation?.events?.length > 0) {
    messages =
      conversation?.events
        ?.filter((evnt) => evnt.event === "user" || evnt.event === "bot")
        ?.map((evnt) => ({
          sender: evnt.event === "user" ? "user" : "ruby",
          message: evnt.text,
        })) || [];
  }

  return messages;
};

export const toCurrency = (value) => {
  const currency = new Intl.NumberFormat(process.env?.locale || "en-US", {
    style: "currency",
    currency: process.env?.locale || "USD",
  });

  return currency.format(value);
};

export const serverId = process.env.REACT_APP_SERVER_ID;
export const siteId = process.env.REACT_APP_SITE_ID;
export const storeId = process.env.REACT_APP_STORE_ID;
export const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Avatar, Button, Stack, styled, Typography } from "@mui/material";
// import TransactionDetailsDialog from "./TransactionDetailsDialog";
import { stringAvatar, toCurrency } from "utils/helpers";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { setLoading } from "redux/publicReducer/actions";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "SaleId",
    label: "Transaction ID",
  },
  {
    id: "ChargeDate",
    label: "Date Ordered",
  },
  // { id: "OrderItems", label: "Items", align: "center" },
  {
    id: "Total",
    label: "Total",
    align: "center",
  },
  //   {
  //     id: "Actions",
  //     label: "",
  //     numeric: true,
  //   },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell?.align || "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const TransactionsTable = ({ transactions, timeValue, setTimeValue }) => {
  const [open, setOpen] = useState(false);
  const [selectedSaleId, setSelectedSaleId] = useState(null);
  const [selectedChargeDate, setSelectedChargeDate] = useState(null);
  const [selectedOrderItems, setSelectedOrderItems] = useState(null);
  const [selectedTotal, setSelectedTotal] = useState(null);
  const [saleData, setSaleData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (SaleId, ChargeDate, OrderItems, Total) => {
    setSelectedSaleId(SaleId);
    setSelectedChargeDate(ChargeDate);
    setSelectedOrderItems(OrderItems);
    // console.log(selectedOrderItems);
    setSelectedTotal(Total);
    setOpen(true);
  };

  const getDataForSaleId = async (selectedSaleId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://ecommv2.servingintel.com/customer/sale-items/${serverId}/${siteId}/${selectedSaleId}`
      );
      const data = await response.json();
      // console.log(data);
      setSaleData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  useEffect(() => {
    if (selectedSaleId) {
      getDataForSaleId(selectedSaleId);
    }
  }, [selectedSaleId]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rows = useMemo(() => {
    return transactions.map(({ Total, ...others }) => {
      return {
        ...others,
        Total: parseInt(Total),
      };
    });
  }, [transactions]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>TRANSACTION</DialogTitle>
          <DialogContent dividers>
            <p>
              Transanction ID:
              {isLoading ? <Skeleton /> : <strong> {selectedSaleId} </strong>}
            </p>
            <p>
              Date Ordered:
              {isLoading ? (
                <Skeleton />
              ) : (
                <strong>
                  {" "}
                  {moment(selectedChargeDate).format("MMM. DD, YYYY")}{" "}
                </strong>
              )}
            </p>

            <Divider
              sx={{
                borderStyle: "dashed",
                borderColor: "black",
                my: 2,
              }}
            />
            {isLoading ? (
              <div>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            ) : (
              <div>
                {saleData.map((item) =>
                  item.Quantity !== 0 ? (
                    <Box key={item.SaleItemId}>
                      <Stack direction="row" gap={2} alignItems="center">
                        <Typography variant="subtitle2">
                          {item.Quantity}
                        </Typography>
                        <Typography variant="subtitle2">
                          {item.ReceiptDescription}
                        </Typography>

                        <Typography flex={1} align="right">
                          {toCurrency(item.BasePrice * item.Quantity)}
                        </Typography>
                      </Stack>
                    </Box>
                  ) : null
                )}
              </div>
            )}
            <Divider
              sx={{
                borderStyle: "dashed",
                borderColor: "black",
                my: 2,
              }}
            />
            <Typography align="right">
              Total:
              {isLoading ? (
                <Skeleton />
              ) : (
                <strong>{toCurrency(selectedTotal || 0)}</strong>
              )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            marginBottom: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            width="100%"
            maxWidth="max-content"
            fontWeight="bold"
          >
            Date Filter:
          </Typography>
          <FormControl
            fullWidth
            sx={{
              maxWidth: 200,
            }}
          >
            <Select
              value={timeValue}
              label="Date Filter"
              variant="standard"
              onChange={(e) => setTimeValue(e.target.value)}
            >
              <MenuItem value={0}>Current Day</MenuItem>
              <MenuItem value={1}>Previous Day</MenuItem>
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={30}>Last Month</MenuItem>
              <MenuItem value={30}>Last 3 Months</MenuItem>
              <MenuItem value={30}>Last 12 Months</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 200, background: "white" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    hover
                    // onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleClick(
                        row.SaleId,
                        row.ChargeDate,
                        row.OrderItems,
                        row.Total
                      )
                    }
                  >
                    <TableCell
                      sx={{
                        ":hover": {
                          color: "rgb(52 100 213)",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {row?.SaleId.slice(0, 7)}..
                    </TableCell>
                    <TableCell>
                      {moment(row?.ChargeDate).format("MMM. DD, YYYY")}
                    </TableCell>
                    {/* <TableCell
                      component="th"
                      id={labelId}
                      // align="left"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        {row?.OrderItems?.length > 0 &&
                          row.OrderItems.map((item, index) =>
                            item.Quantity !== 0 ? (
                              <Stack
                                key={index}
                                direction="row"
                                gap={2}
                                alignItems="center"
                              >
                                <Typography width="100%">
                                  {item.ReceiptDescription}:
                                </Typography>
                                <Typography width="100%">
                                  {toCurrency(item.BasePrice)} X {item.Quantity}
                                </Typography>
                                <Typography width="100%">
                                  {toCurrency(item.BasePrice * item.Quantity)}
                                </Typography>
                              </Stack>
                            ) : null
                          )}
                      </Box>
                    </TableCell> */}
                    <TableCell align="center">
                      {toCurrency(row?.Total || 0)}
                    </TableCell>

                    {/* <TableCell align="right">
                      <TransactionDetailsDialog transactionDetails={row} />
                    </TableCell> */}
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};
export default TransactionsTable;
